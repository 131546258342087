import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {formatDate} from '@angular/common';
import {CommonPdfGenerator} from '../../services/exportPDF/common-pdf-generator';
import moment from 'moment';

export class PdfGenerator extends CommonPdfGenerator {
	generatePdf(
		rfiDetails,
		status,
		materialCosts,
		commercialCosts,
		isToolingWholeOption,
		toolingTotal,
		totCurvRFI,
		totPrevRFI,
		rfiHistoryList,
		buyerSupplier,
		selectedType,
		isLumpSumSelected,
		lsIncludeFInValue,
		fileNameRole
	) {
		const versionData = rfiDetails.rfiId;
		const fileName = `${fileNameRole}-${rfiDetails.rfiId}`;
		const documentDefinition = {
			info: this.getPdfTitle(`${selectedType} Information`),
			pageSize: 'LEGAL',
			pageOrientation: 'landscape',
			content: this.getPdfContent(
				rfiDetails,
				status,
				materialCosts,
				commercialCosts,
				isToolingWholeOption,
				toolingTotal,
				totCurvRFI,
				totPrevRFI,
				rfiHistoryList,
				buyerSupplier,
				selectedType,
				isLumpSumSelected,
				lsIncludeFInValue
			),
			footer(currentPage, pageCount) {
				if (currentPage === pageCount) {
					return {
						columns: [
							{
								text:
									'Created : ' +
									formatDate(
										rfiDetails.rfiCreatedDate,
										'MM/dd/yyyy @ h:mma',
										'en-US'
									),
								fontSize: 10,
								alignment: 'center',
								width: '20%'
							},
							{
								text:
									'Last Updated : ' +
									formatDate(
										rfiDetails.rfiModifiedDate,
										'MM/dd/yyyy @ h:mma',
										'en-US'
									),
								fontSize: 10,
								alignment: 'left',
								width: '30%'
							},
							{
								text:
									currentPage.toString() + ' of ' + pageCount,
								alignment: 'left',
								width: '35%'
							},
							{
								text: versionData,
								alignment: 'center',
								fontSize: 10,
								width: '15%'
							}
						]
					};
				} else {
					return {
						columns: [
							{
								text:
									currentPage.toString() + ' of ' + pageCount,
								alignment: 'right',
								width: '50%'
							},
							{
								text: versionData,
								alignment: 'right',
								fontSize: 10,
								width: '45%'
							}
						]
					};
				}
			},
			styles: {
				centerHeader: {
					fontSize: 15,
					bold: true,
					alignment: 'center',
					margin: [0, 0, 0, 10]
				},
				subheader: {
					fontSize: 12,
					bold: true,
					margin: [0, 2, 0, 5]
				},
				tableStyle: {
					margin: [0, 5, 0, 10],
					fontSize: 10
				},
				subTableStyle: {
					margin: [0, 0, 0, 5],
					fontSize: 10
				},
				tableHeader: {
					bold: true,
					fontSize: 10,
					color: 'black',
					alignment: 'center'
				},
				rightAlignedLabel: {
					bold: true,
					fontSize: 10,
					color: 'black',
					alignment: 'right'
				},
				leftAligned: {
					bold: false,
					fontSize: 10,
					color: 'black',
					alignment: 'left'
				},
				leftAlignedLabel: {
					bold: true,
					fontSize: 10,
					color: 'black',
					alignment: 'left'
				},
				centerAligned: {
					fontSize: 10,
					color: 'black',
					alignment: 'center'
				},
				centerAlignedLabel: {
					bold: true,
					fontSize: 10,
					color: 'black',
					alignment: 'center'
				},
				centerAlignedLabelBlue: {
					bold: true,
					fontSize: 10,
					color: 'blue',
					alignment: 'center'
				},
				centerAlignedLabelRed: {
					bold: true,
					fontSize: 10,
					color: 'red',
					alignment: 'center'
				}
			},
			defaultStyle: this.defaultStyle()
		};
		pdfMake.createPdf(documentDefinition).download(fileName);
	}

	rfiInformation(rfiRequest, buyerSupplier, selectedType) {
		let submissionDetailsDocumentDefinition = [];
		submissionDetailsDocumentDefinition = [
			[
				{text: `${selectedType} Information`, style: 'subheader'},
				'',
				'',
				'',
				'',
				''
			],
			[
				{text: `${selectedType} # : `, style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.rfiId),
				'',
				{
					text: `${selectedType} Created Date : `,
					style: 'rightAlignedLabel'
				},
				moment(rfiRequest.rfiModifiedDate).format('MMMM-D-yy'),
				''
			],
			[
				{text: 'Lead Program : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.leadPrg),
				'',
				{text: 'PSA # : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.psa),
				''
			],
			[
				{text: 'Buyer CDSID : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.buyerCode),
				'',
				{text: 'D&R CDSID : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.drCDSID),
				''
			],
			[
				{text: 'Second D&R / ESA : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.secondaryEngineer),
				'',
				{text: 'Cost Estimator : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.costEstimator),
				''
			],
			[
				{
					text: 'Supplier Email : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.supplier),
				'',
				{
					text: 'Supplier Engineer Email : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.secondSupplier),
				''
			],
			[
				{
					text: 'Material Group/Commodity Code : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.purCommCode),
				'',
				{
					text: 'Purchasing Group/Buyer Code : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.purGroup),
				''
			],
			[
				{
					text: 'Supplier GSDB Code : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.siteCode),
				'',
				{text: 'MP&L Plant Code : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.plantCode),
				''
			],
			[
				{
					text: 'Modular Catalogue Name : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.moduleName),
				'',
				{
					text: 'Modular Catalogue Type : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.moduleType),
				''
			],
			[
				{
					text: 'Part Number/Material Number : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.partNumber),
				'',
				{text: 'Part Name : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.partName),
				''
			],
			[
				{text: 'CMF / DCR # : ', style: 'rightAlignedLabel'},
				this.getValidValueForPdf(rfiRequest.cmfDCR),
				'',
				{
					text: 'CMF/DCR Title : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.cmfDCRTitle),
				''
			],
			[
				{
					text: 'Design Change Description : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.changeDesc),
				'',
				{
					text: 'Supplier Name : ',
					style: 'rightAlignedLabel'
				},
				this.getValidValueForPdf(rfiRequest.supplierParentName),
				''
			],
			['', '', '', '', '', ''],
			['', '', '', '', '', ''],
			['', '', '', '', '', ''],
			['', '', '', '', '', '']
		];

		if (buyerSupplier === 'buyer') {
			submissionDetailsDocumentDefinition.push(
				[
					{text: 'Internal Cost Details ', style: 'subheader'},
					'',
					'',
					'',
					'',
					''
				],
				[
					{
						text: 'Initial Piece Price Target : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdfDecimal(rfiRequest.partCostTarget),
					'',
					{
						text: 'Currency : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdf(rfiRequest.partCostTargetCur),
					''
				],
				[
					{
						text: 'Piece Price Status : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdfDecimal(
						rfiRequest.pdMatCostEstimate
					),
					'',
					{
						text: 'Currency : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdf(rfiRequest.pdMatCostEstimateCur),
					''
				],
				[
					{
						text: 'Piece Price Should Cost : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdfDecimal(
						rfiRequest.e3pMatCostEstimate
					),
					'',
					{
						text: 'Currency : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdf(rfiRequest.e3pMatCostEstimateCur),
					''
				],
				[
					{
						text: 'Initial Production Tooling Target : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdfDecimal(
						rfiRequest.toolingCostTarget
					),
					'',
					{
						text: 'Currency: ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdf(rfiRequest.toolingCostTargetCur),
					''
				],
				[
					{
						text: 'Production Tooling Status : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdfDecimal(
						rfiRequest.pdToolingEstimate
					),
					'',
					{
						text: 'Currency : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdf(rfiRequest.pdToolingEstimateCur),
					''
				],
				[
					{
						text: 'Tooling Should Cost : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdfDecimal(
						rfiRequest.e3pToolingEstimate
					),
					'',
					{
						text: 'Currency : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdf(rfiRequest.e3pToolingEstimateCur),
					''
				],
				[
					{
						text: 'Should Cost Created By : ',
						style: 'rightAlignedLabel'
					},
					this.getValidValueForPdf(rfiRequest.proposedCostMaturity), // Assuming rfiRequest.proposedCostMaturity holds the value
					'',
					'',
					'',
					''
				]
			);
		}

		return submissionDetailsDocumentDefinition;
	}

	buildPiecePriceList(materialCosts, rfiDetails) {
		const supplierBidDocumentDefinition = [
			[
				{text: 'Cost Breakdown', style: 'tableHeader'},
				{
					text: `Previous RFI/RFQ Cost (${rfiDetails.resCur})`,
					style: 'tableHeader'
				},
				{
					text: `RFI/RFQ Cost (${rfiDetails.resCur})`,
					style: 'tableHeader'
				},
				{
					text: 'Delta (Positive[+] Delta = Increase) (Negative[-] Delta = Decrease)',
					style: 'tableHeader'
				}
			]
		];
		if (materialCosts !== undefined && materialCosts != null) {
			materialCosts.forEach((materialCostDetail) => {
				if (!materialCostDetail.isTotal) {
					supplierBidDocumentDefinition.push([
						{
							text: materialCostDetail.costBucket,
							style: 'centerAligned'
						},
						{
							text: this.get4Decimal(materialCostDetail.prevRFI),
							style: 'centerAligned'
						},
						{
							text: this.get4Decimal(materialCostDetail.curRFI),
							style: 'centerAligned'
						},
						{
							text: this.getRFIDiff(
								materialCostDetail.curRFI,
								materialCostDetail.prevRFI
							),
							style: 'centerAligned'
						}
					]);
				} else {
					supplierBidDocumentDefinition.push([
						{
							text: materialCostDetail.costBucket,
							style: 'centerAlignedLabel'
						},
						{
							text: this.get4Decimal(materialCostDetail.prevRFI),
							style: 'centerAlignedLabel'
						},
						{
							text: this.get4Decimal(materialCostDetail.curRFI),
							style: 'centerAlignedLabel'
						},
						{
							text: this.getRFIDiff(
								materialCostDetail.curRFI,
								materialCostDetail.prevRFI
							),
							style: 'centerAlignedLabel'
						}
					]);
				}
			});
		}

		return supplierBidDocumentDefinition;
	}

	buildCommercialAdjustment(
		commercialCosts,
		edt,
		isLumpSumSelected,
		lsIncludeFInValue,
		rfiDetails
	) {
		let supplierBidDocumentDefinition = [
			[
				{text: 'Cost Breakdown', style: 'tableHeader'},
				{
					text: `Previous RFI/RFQ Cost (${rfiDetails.resCur})`,
					style: 'tableHeader'
				},
				{
					text: `RFI/RFQ Cost (${rfiDetails.resCur})`,
					style: 'tableHeader'
				},
				{
					text: 'Delta (Positive[+] Delta = Increase) (Negative[-] Delta = Decrease)',
					style: 'tableHeader'
				}
			]
		];
		if (edt) {
			supplierBidDocumentDefinition = [
				[
					{text: 'Cost Breakdown', style: 'tableHeader'},
					{
						text: `Previous RFI/RFQ Lump Sum ED&T (${rfiDetails.resCur})`,
						style: 'tableHeader'
					},
					{
						text: `RFI/RFQ Cost (${rfiDetails.resCur})`,
						style: 'tableHeader'
					},
					{
						text: 'Delta (Positive[+] Delta = Increase) (Negative[-] Delta = Decrease)',
						style: 'tableHeader'
					}
				]
			];
		}

		if (commercialCosts !== undefined && commercialCosts != null) {
			commercialCosts.forEach((materialCostDetail) => {
				if (edt) {
					if (
						materialCostDetail.costBucket ===
						'ED&T paid in Piece Price'
					) {
						supplierBidDocumentDefinition.push([
							{
								text:
									materialCostDetail.costBucket ==
									'ED&T paid in Piece Price'
										? isLumpSumSelected == undefined ||
											isLumpSumSelected == false
											? 'ED&T paid in Piece Price Value Includes Financing - '
											: ' ED&T paid in Piece Price \n Value Includes Financing - ' +
												lsIncludeFInValue
										: materialCostDetail.costBucket,
								style: 'centerAligned'
							},
							{
								text: this.get4Decimal(
									materialCostDetail.prevRFI
								),
								style: 'centerAligned'
							},
							{
								text: this.get4Decimal(
									materialCostDetail.curRFI
								),
								style: 'centerAligned'
							},
							{
								text: this.getRFIDiff(
									materialCostDetail.curRFI,
									materialCostDetail.prevRFI
								),
								style: 'centerAligned'
							}
						]);
					}
				} else {
					if (!materialCostDetail.isTotal) {
						supplierBidDocumentDefinition.push([
							{
								text:
									materialCostDetail.costBucket ==
									'ED&T paid in Piece Price'
										? isLumpSumSelected == undefined ||
											isLumpSumSelected == true
											? 'ED&T paid in Piece Price Value Includes Financing - '
											: ' ED&T paid in Piece Price \n Value Includes Financing - ' +
												lsIncludeFInValue
										: materialCostDetail.costBucket,
								style: 'centerAligned'
							},
							{
								text: this.get4Decimal(
									materialCostDetail.prevRFI
								),
								style: 'centerAligned'
							},
							{
								text: this.get4Decimal(
									materialCostDetail.curRFI
								),
								style: 'centerAligned'
							},
							{
								text: this.getRFIDiff(
									materialCostDetail.curRFI,
									materialCostDetail.prevRFI
								),
								style: 'centerAligned'
							}
						]);
					} else {
						supplierBidDocumentDefinition.push([
							{
								text: materialCostDetail.costBucket,
								style: 'centerAlignedLabel'
							},
							{
								text: this.get4Decimal(
									materialCostDetail.prevRFI
								),
								style: 'centerAlignedLabel'
							},
							{
								text: this.get4Decimal(
									materialCostDetail.curRFI
								),
								style: 'centerAlignedLabel'
							},
							{
								text: this.getRFIDiff(
									materialCostDetail.curRFI,
									materialCostDetail.prevRFI
								),
								style: 'centerAlignedLabel'
							}
						]);
					}
				}
			});
		}

		return supplierBidDocumentDefinition;
	}

	buildHistory(rfiHistoryList) {
		const supplierBidDocumentDefinition = [
			[
				{text: 'Updated By', style: 'tableHeader'},
				{text: 'Piece Price Subtotal', style: 'tableHeader'},
				{text: 'Piece Price Subtotal Delta', style: 'tableHeader'},
				{text: 'Toling Price Subtotal', style: 'tableHeader'},
				{text: 'Toling Price Subtotal Delta', style: 'tableHeader'},
				{text: 'Commercial Subtotal', style: 'tableHeader'},
				{text: 'Commercial Subtotal Delta', style: 'tableHeader'},
				{text: 'Lump Sum ED&T Subtotal', style: 'tableHeader'},
				{text: 'Lump Sum ED&T Subtotal Delta', style: 'tableHeader'},
				{text: 'Date & Time', style: 'tableHeader'}
			]
		];

		rfiHistoryList.forEach((rfiHistory) => {
			supplierBidDocumentDefinition.push([
				{
					text: this.getValidValueForPdf(rfiHistory.createdBy),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(
						rfiHistory.piecePrice
					),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(
						rfiHistory.piecePriceDelta
					),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(
						rfiHistory.toolingPrice
					),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(
						rfiHistory.toolingPriceDelta
					),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(rfiHistory.commPrice),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(
						rfiHistory.commPriceDelta
					),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(
						rfiHistory.lumpSumPrice
					),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(
						rfiHistory.lumSumPriceDelta
					),
					style: 'rightAligned'
				},
				{
					text: this.getValidValueForPdf(rfiHistory.createDate),
					style: 'rightAligned'
				}
			]);
		});

		return supplierBidDocumentDefinition;
	}

	getValidValueForPdf(val) {
		return val || '';
	}

	getValidValueForPdfDecimal(val) {
		return val || '0.0000';
	}

	buildProductonTooling(rfiRequest, isToolingWholeOption, toolingTotal) {
		const supplierBidDocumentDefinition = [
			[
				{text: '', style: 'tableHeader'},
				{
					text: `Previous RFI/RFQ Production Tooling (${rfiRequest.resCur})`,
					style: 'tableHeader'
				},
				{
					text: `Supplier’s New Total Tooling (${rfiRequest.resCur})`,
					style: 'tableHeader'
				},
				{
					text: 'Delta (Positive[+] Delta = Increase) (Negative[-] Delta = Decrease)',
					style: 'tableHeader'
				}
			]
		];

		if (isToolingWholeOption !== 'true') {
			supplierBidDocumentDefinition.push([
				{text: 'Production Tooling', style: 'centerAligned'},
				{
					text: this.getValidValueForPdfDecimal(
						rfiRequest.rfiPrevCost
					),
					style: 'centerAligned'
				},
				{
					text: this.getRFIAdd(rfiRequest.rfiPrevCost, toolingTotal),
					style: 'centerAligned'
				},
				{
					text: this.getRFIDelta(
						rfiRequest,
						isToolingWholeOption,
						toolingTotal
					),
					style: 'centerAligned'
				}
			]);
		} else {
			supplierBidDocumentDefinition.push([
				{text: 'Production Tooling', style: 'centerAligned'},
				{
					text: this.getValidValueForPdfDecimal(
						rfiRequest.rfiPrevCost
					),
					style: 'centerAligned'
				},
				{
					text: this.getValidValueForPdfDecimal(rfiRequest.rfiCost),
					style: 'centerAligned'
				},
				{
					text: this.getRFIDelta(
						rfiRequest,
						isToolingWholeOption,
						toolingTotal
					),
					style: 'centerAligned'
				}
			]);
		}

		return supplierBidDocumentDefinition;
	}

	buildTotalCost(totPrevRFI, totCurvRFI) {
		const supplierBidDocumentDefinition = [
			[
				{
					text: 'Total Cost (Piece Price + Commercial Adjustments)',
					style: 'tableHeader'
				},
				{text: this.get4Decimal(totPrevRFI), style: 'tableHeader'},
				{text: this.get4Decimal(totCurvRFI), style: 'tableHeader'},
				{
					text: this.getRFIDiff(totCurvRFI, totPrevRFI),
					style: 'tableHeader'
				}
			]
		];

		return supplierBidDocumentDefinition;
	}

	buildComments(rfiRequest, buyerSupplier) {
		const supplierBidDocumentDefinition = [
			[
				{text: 'Supplier Comments (Optional)', style: 'tableHeader'},
				this.getValidValueForPdf(rfiRequest.rfiComments)
			],
			[
				{text: 'Buyer Comments (Optional)', style: 'tableHeader'},
				this.getValidValueForPdf(rfiRequest.buyerComments)
			],
			[
				{text: 'PD Comments (Optional)', style: 'tableHeader'},
				this.getValidValueForPdf(rfiRequest.pdComments)
			]
		];

		if (buyerSupplier === 'buyer') {
			supplierBidDocumentDefinition.push([
				{text: 'Internal Comments (Optional)', style: 'tableHeader'},
				this.getValidValueForPdf(rfiRequest.internalComment)
			]);
		}

		return supplierBidDocumentDefinition;
	}

	get4Decimal(prevRFI) {
		return (Math.sign(prevRFI) * Math.abs(prevRFI)).toFixed(5);
	}

	getRFIAdd(prevRFI, totaTooling) {
		const add: number = Number(totaTooling) + Number(prevRFI);
		const result = add.toFixed(0);
		return result;
	}

	getRFIDiff(prevRFI, curRFI) {
		const diff = curRFI - prevRFI;
		const result =
			(Math.sign(diff) === 1 ? '-' : Math.sign(diff) === -1 ? '+' : '') +
			Math.abs(diff).toFixed(5);

		return result;
	}

	getRFIDelta(rfiRequest, isToolingWholeOption, toolingTotal) {
		let diff = 0;
		if (isToolingWholeOption !== 'true') {
			diff = toolingTotal;
		} else {
			diff = rfiRequest.rfiCost - rfiRequest.rfiPrevCost;
		}

		//return diff.toFixed(4);

		return (
			(Math.sign(diff) === -1 ? '-' : Math.sign(diff) === 1 ? '+' : '') +
			Math.abs(diff).toFixed(0)
		);
	}

	getPdfContent(
		rfiDetails,
		status,
		materialCosts,
		commercialCosts,
		isToolingWholeOption,
		toolingTotal,
		totCurvRFI,
		totPrevRFI,
		rfiHistoryList,
		buyerSupplier,
		selectedType,
		isLumpSumSelected,
		lsIncludeFInValue
	) {
		const pdfData =
			status === 'INITIAL RESPONSE REQUIRED'
				? [
						{text: status, style: 'centerHeader'},
						{
							table: {
								widths: [
									'25%',
									'15%',
									'10%',
									'25%',
									'15%',
									'10%'
								],
								body: this.rfiInformation(
									rfiDetails,
									buyerSupplier,
									selectedType
								)
							},
							layout: 'noBorders',
							style: 'tableStyle'
						}
					]
				: [
						{text: status, style: 'centerHeader'},
						{
							table: {
								widths: [
									'25%',
									'15%',
									'10%',
									'25%',
									'15%',
									'10%'
								],
								body: this.rfiInformation(
									rfiDetails,
									buyerSupplier,
									selectedType
								)
							},
							layout: 'noBorders',
							style: 'tableStyle'
						},
						{
							text: 'Piece Price',
							style: 'subheader',
							pageBreak: 'before'
						},
						{
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: this.buildPiecePriceList(
									materialCosts,
									rfiDetails
								)
							},
							layout: this.buildTableLayout('black', 'gray'),
							style: 'tableStyle'
						},
						{text: 'Commercial Adjustment', style: 'subheader'},
						{
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: this.buildCommercialAdjustment(
									commercialCosts,
									false,
									isLumpSumSelected,
									lsIncludeFInValue,
									rfiDetails
								)
							},
							layout: this.buildTableLayout('black', 'gray'),
							style: 'tableStyle'
						},
						{text: 'Total Costs', style: 'subheader'},
						{
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: this.buildTotalCost(
									totPrevRFI,
									totCurvRFI
								)
							},
							layout: this.buildTableLayout('black', 'gray'),
							style: 'tableStyle'
						},
						{
							text: 'Lump Sum ED&T',
							style: 'subheader',
							pageBreak: 'before'
						},
						{
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: this.buildCommercialAdjustment(
									commercialCosts,
									true,
									isLumpSumSelected,
									lsIncludeFInValue,
									rfiDetails
								)
							},
							layout: this.buildTableLayout('black', 'gray'),
							style: 'tableStyle'
						},
						{text: 'Production Tooling', style: 'subheader'},
						{
							table: {
								widths: ['25%', '25%', '25%', '25%'],
								body: this.buildProductonTooling(
									rfiDetails,
									isToolingWholeOption,
									toolingTotal
								)
							},
							layout: this.buildTableLayout('black', 'gray'),
							style: 'tableStyle'
						},
						{
							text: 'History',
							style: 'subheader',
							pageBreak: 'before'
						},
						{
							table: {
								widths: [
									'10%',
									'10%',
									'10%',
									'10%',
									'10%',
									'10%',
									'10%',
									'10%',
									'10%',
									'10%'
								],
								body: this.buildHistory(rfiHistoryList)
							},
							layout: this.buildTableLayout('black', 'gray'),
							style: 'tableStyle'
						},
						{text: 'Comments', style: 'subheader'},
						{
							table: {
								widths: ['35%', '65%'],
								body: this.buildComments(
									rfiDetails,
									buyerSupplier
								)
							},
							layout: this.buildTableLayout('black', 'gray'),
							style: 'tableStyle'
						}
					];

		return pdfData;
	}
}
